<script setup>
/**
 * DocumillLoader component.
 *
 * How to use:
 * <DocumillLoader
 *  :show="true"
 * >
 * </DocumillLoader>
 *
 * @author Documill 2024
 */

const props = defineProps({
  /**
   * Whether to display loading.
   */
  show: {
    type: Boolean,
    require: true,
  },
})

</script>
<template>
  <section :class="[props.show && 'show', 'documill-loader']">
    <div class="loader-cover"></div>
    <div class="loader"></div>
  </section>
</template>