/**
 * Vue Store related to UI state information.
 *
 * Do note that this module is namespaced so refer to it's actions and
 * mutations with 'ui/' prefix.
 *
 * @author Documill 2022
 */

import axios from 'axios';

import { logger } from '../../utils/logger';
import Workflows from './../../utils/workflows';

export default {

  namespaced: true,

  state: {
    ui: {
      /**
       * Currently running file uploads. Keys are file names and values are
       * numbers representing percentual progress between 0-100.
       */
      fileUploads: {},

      /** Whether to show a fullscreen loading page. */
      fullscreenLoader: false,

      // TODO: Consider creating separation for different UI parts, e.g.
      //       "main-area", "header", "footer", "toolbar" etc.

      /** Items that make whole UI "busy" (or to being in "loading" state). */
      itemsBusy: {},

      /** Whether there is newer UI version available. */
      newVersionAvailable: false,

      /** Task identities that should have loading indicator. */
      tasksCompleting: {},

      /** Current UI version. */
      version: null,

      /**
       * Tracks the current workflow saving state.
       *
       * Can have three different states: SAVING, SAVED, NOT_SAVED. See DOS-1936 and "workflows.js".
       */
      workflowSaveStatus: Workflows.SAVING_STATUS.SAVED,

      /**
       * Project tasks view task status filters.
       */

      projectTaskStatusFilters: {
        AVAILABLE: true,
        UPCOMING: false,
        IN_PROGRESS: true,
        REJECTED: true,
        PAUSED: false,
        COMPLETED: true,
        CANCELED: false,
      },
    }
  },

  getters: {

    /**
     * Gets the object map containing file uploads. Keys are the file names
     * and values is the current progress.
     *
     * @returns {*} object map of file uploads
     */

    getUploadFiles: (state) => {
      return state.ui.fileUploads;
    },

    /**
     * Gets the current UI version.
     *
     * How to use:
     * this.$store.getters['ui/getVersion']
     *
     * @returns {String} version (may be null, if version is not yet read)
     */

    getVersion: (state) => {
      return state.ui.version;
    },

    /**
     * Tests if there are any UI items that have caused the whole UI become
     * "busy".
     *
     * How to use:
     * this.$store.getters['ui/isBusy']
     *
     * @returns {Boolean} whether the UI is loading ("busy")
     */

    isBusy: (state) => {
      return Object.keys(state.ui.itemsBusy).length > 0;
    },

    /**
     * Tests whether a fullscreen loading page has been requested.
     *
     * How to use:
     * this.$store.getters['ui/isFullscreenLoader']
     *
     * @returns {Boolean} whether a fullscreen loading page has been requested
     */

    isFullscreenLoader: (state) => {
      return state.ui.fullscreenLoader;
    },

    /**
     * Tests if there is a specific UI items that have caused the whole UI become "busy".
     *
     * How to use:
     * this.$store.getters['ui/isItemBusy'](itemName)
     *
     * @param item item name
     *
     * @returns {Boolean} whether the UI is loading ("busy")
     */

    isItemBusy: (state) => itemName => {
      return state.ui.itemsBusy[itemName] != null;
    },

    /**
     * Tests if there is a new UI version available. What this means is that the end-user is
     * currently using old UI version while the front-end code has changed in server.
     *
     * How to use:
     * this.$store.getters['ui/isNewVersionAvailable']
     *
     * @returns {Boolean} whether a new UI version is available
     */

    isNewVersionAvailable: (state) => {
      return state.ui.newVersionAvailable;
    },

    /**
     * Tests if a specific task is completing (i.e. completing process has been started but the task
     * has not been yet actually completed).
     *
     * How to use:
     * this.$store.getters['ui/isTaskCompleting'](taskId)
     *
     * @param taskId task identity
     *
     * @returns {Boolean} whether the task is completing
     */

    isTaskCompleting: (state) => taskId => {
      return state.ui.tasksCompleting[taskId] != null;
    },

    /**
     * Tests if there are any file uploads running.
     *
     * How to use:
     * this.$store.getters['ui/isUploadRunning']
     *
     * @returns {Boolean} whether an upload is running
     */

    isUploadRunning: (state) => {
      return Object.keys(state.ui.fileUploads).length > 0;
    },

    /**
     * Checks if workflow saving is done or in process.
     *
     * How to use:
     * this.$store.getters['ui/getWorkflowStatus']
     *
     * @returns {String} workflow saving state (SAVING, SAVED, NOT_SAVED)
     */

    getWorkflowStatus: (state) => {
      return state.ui.workflowSaveStatus;
    },

    projectTaskStatusFilters: (state) =>{
      return state.ui.projectTaskStatusFilters;
    }
  },

  mutations: {

    /**
     * Lets the state know that the following UI item has made the whole
     * UI "busy".
     *
     * How to use:
     * this.$store.commit("ui/ADD_BUSY_ITEM","item");
     *
     * @param state state
     * @param {*} item item that caused the UI to become "busy"
     */

    ADD_BUSY_ITEM(state, item) {
      state.ui.itemsBusy[item] = true;
    },

    /**
     * Lets the state know that there is a new file upload for a file.
     *
     * How to use:
     * this.$store.commit("ui/ADD_FILE_UPLOAD_ITEM","fileName");
     *
     * @param state state
     * @param {String} fileName fileName of the file that is being uploaded
     */

    ADD_FILE_UPLOAD_ITEM(state, fileName) {
      state.ui.fileUploads = { ...state.ui.fileUploads, [fileName]: 0 };
    },

    /**
     * Lets the state know that the following task is "in-progress" but the completing process
     * has been started.
     *
     * How to use:
     * this.$store.commit("ui/ADD_TASK_COMPLETING","taskId");
     *
     * @param state state
     * @param {*} taskId task identity
     */

    ADD_TASK_COMPLETING(state, taskId) {
      state.ui.tasksCompleting[taskId] = true;
    },

    /**
     * Lets the state know that workflow saving is in process.
     *
     * How to use:
     * this.$store.commit("ui/ADD_WORKFLOW_SAVING");
     *
     * @param state state
     */

    ADD_WORKFLOW_SAVING(state) {
      state.ui["workflowSaveStatus"] = Workflows.SAVING_STATUS.SAVING;
    },

    /**
     * Lets the state know if workflow saving is no longer in process.
     *
     * How to use:
     * this.$store.commit("ui/REMOVE_WORKFLOW_SAVING",success);
     *
     * @param state state
     * @param {Boolean} success whether saving succeeded or not
     */

    REMOVE_WORKFLOW_SAVING(state,success) {
      if(success === true)
        state.ui['workflowSaveStatus'] = Workflows.SAVING_STATUS.SAVED;
      else
        state.ui['workflowSaveStatus']  = Workflows.SAVING_STATUS.NOT_SAVED;
    },

    /**
     * Lets the state know that the following UI item is no longer making the
     * whole UI "busy".
     *
     * How to use:
     * this.$store.commit("ui/REMOVE_BUSY_ITEM","item");
     *
     * @param state state
     * @param {*} item item that caused the UI to become "busy"
     */

    REMOVE_BUSY_ITEM(state, item) {
      delete state.ui.itemsBusy[item];
    },

    /**
     * Lets the state know that the file upload for a particular file name has
     * finished and can be removed from the state.
     *
     * How to use:
     * this.$store.commit("ui/REMOVE_FILE_UPLOAD_ITEM","fileName");
     *
     * @param state state
     * @param {String} fileName file name
     */

    REMOVE_FILE_UPLOAD_ITEM(state, fileName) {
      delete state.ui.fileUploads[fileName];
    },

    /**
     * Lets the state know that the task has completed (or failed to complete) and is no longer
     * trying to complete.
     *
     * How to use:
     * this.$store.commit("ui/REMOVE_TASK_COMPLETING","taskId");
     *
     * @param state state
     * @param {String} taskId task identity
     */

    REMOVE_TASK_COMPLETING(state, taskId) {
      delete state.ui.tasksCompleting[taskId];
    },

    /**
     * Sets whether a fullscreen loading page should be displayed.
     *
     * How to use:
     * this.$store.commit("ui/SET_FULLSCREEN_LOADER",true/false);
     *
     * @param state
     * @param {Boolean} enabled whether fullscreen loading page should be shown
     */

    SET_FULLSCREEN_LOADER(state,enabled) {
      state.ui["fullscreenLoader"] = enabled;
    },

    /**
     * Marks that there is a new UI version available.
     *
     * How to use:
     * this.$store.commit("ui/SET_NEW_VERSION_AVAILABLE");
     *
     * @param state
     */

    SET_NEW_VERSION_AVAILABLE(state) {
      state.ui["newVersionAvailable"]  = true;
    },

    /**
     * Sets the current UI version.
     *
     * How to use:
     * this.$store.commit("ui/SET_VERSION","version");
     *
     * @param state
     * @param {String} version version number
     */

    SET_VERSION(state,version) {
      state.ui["version"] = version;
    },

    /**
     * Updates the progress of a file upload.
     *
     * Expected parameters:
     * params = {
     *   fileName: "String",
     *   progress: "Number" (0-100)
     * }
     *
     * How to use:
     * this.$store.commit("ui/UPDATE_FILE_UPLOAD_PROGRESS",params);
     *
     * @param state state
     * @param {String} fileName file name
     */

    UPDATE_FILE_UPLOAD_PROGRESS(state, params) {
      delete state.ui.fileUploads[params.fileName];
      state.ui.fileUploads = { ...state.ui.fileUploads, [params.fileName]: params.progress }
    },

    SET_TASK_STATUS_FILTERS(state,filters){
      state.ui["projectTaskStatusFilters"] = filters;
    },

    RESET_TASK_STATUS_FILTERS(state) {
      state.ui["projectTaskStatusFilters"] = { AVAILABLE: true,
                                              UPCOMING: false,
                                              IN_PROGRESS: true,
                                              REJECTED: true,
                                              PAUSED: false,
                                              COMPLETED: true,
                                              CANCELED: false,
                                            };
    }

  },

  actions: {
    // Note: No need for actions as "isBusy" state changes are preferred to be
    //       synchronous instead of asynchronous (actions).
    // TODO: They could still be useful in some situations.

    /**
     * Reads the UI version information from server.
     *
     * How to use:
     * this.$store.dispatch("ui/readVersion");
     */
    async readVersion(context) {
      // Simply add timestamp to request to bust browser cache.
      // Note: We can't use "api.js" as it sets the basePath to target the Leap flow-controller-api.
      //       Because of that the "raw" axios is used.
      // TODO: Could probably also try setting no-cache headers.
      // TODO: We could also read other build related information.
      // TODO: See DOS-2870 for situation where running both old and new version at
      //       the same time causes instability.

      let url = "/build.json?" + new Date().getTime();
      try {
        const result = await axios.get(url);
        let env = result.data;

        context.commit("SET_VERSION",env.version);
        return result;
      }
      catch(error) {
        logger.error("Failed to query UI version information.",error);
        throw error;
      }
    },
  },
}
